import React from "react"
import Speaker from "./speaker"
import { Link } from "gatsby"
import useKeynoteSpeakers from "../../hooks/useKeynoteSpeakers"

const Keynote = () => {
    const keynoteSpeakers = useKeynoteSpeakers()

    return(
        <section id="keynote" className="flex flex-col px-6 md:px-8 lg:px-16 py-20 bg-light">
            <h1 className="font-black text-3xl text-primary text-center" data-sal="fade" data-sal-easing="ease" data-sal-duration="600"><Link to="/keynote">Keynote Speakers</Link></h1>
            <div className="flex flex-wrap justify-center items-stretch pt-6">
                <Speaker link="/keynote/#mingzhang" width="w-full md:w-1/2 lg:w-1/3" spacing="pb-4 md:pr-2 md:pb-2" position="Keynote Speaker" name="Dr. Ming Zhang" descriptions={["Professor", "Peking University, China"]} img={keynoteSpeakers.zhang.childImageSharp.fluid}/>
                <Speaker link="/keynote/#freisangil" width="w-full md:w-1/2 lg:w-1/3" spacing="md:pl-2 lg:pl-0 pb-4 md:pb-2" position="Keynote Speaker" name="Frei Sangil" descriptions={["Founder / CEO", "LAYERTech Software Labs (Inc.)"]} img={keynoteSpeakers.sangil.childImageSharp.fluid}/>
                <Speaker link="/keynote/#novaahmed" width="w-full md:w-1/2 lg:w-1/3" spacing="pb-4 md:px-1 md:pb-2 md:pt-2 lg:pt-0" position="Keynote Speaker" name="Dr. Nova Ahmed" descriptions={["Associate Professor", "North South University, Bangladesh"]} img={keynoteSpeakers.ahmed.childImageSharp.fluid}/>
            </div>
        </section>
    )
}

export default Keynote
import { useStaticQuery, graphql } from "gatsby"

const useBanner = () => {
  const banner = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return banner.file.childImageSharp.fluid
}

export default useBanner

import React from "react"

const Theme = () => {
  return (
    <section id="theme" className="container mx-auto flex items-center px-6 pt-12 md:px-8 lg:px-16 md:pt-36 md:pb-8">
      <div className="flex items-stretch">
        <div className="w-full " data-sal="fade" data-sal-easing="ease" data-sal-duration="600">
          <h1 className="font-black text-4xl text-primary">Transforming Computing for a Better Future</h1>
          <sub className="text-xs">ACM Celebration of Women in Computing Asia Pacific (ACM-WAP) 2021 is a virtual event to be held on April 7, 2021 with the theme: <b><i>“Transforming Computing for a Better Future”</i></b></sub>
          <br /><br className="hidden md:inline" />
          <p className="pt-3 leading-relaxed text-sm md:text-base">The celebration aims to bring together women from industry, education, and research to share ideas and experiences in transforming computing towards a future that we want to build. This inaugural event will feature presentations from prominent women who are researchers, educators, and professionals in the computing field in the Asia-Pacific Region.
          <br /><br/>
          This is the first event organized by the newly formed ACM-W Asia-Pacific Committee (ACM-WAP) in association with National University, Manila, Philippines, and the Computing Society of the Philippines - Special Interest Group on Women in Computing (CSP SIG-WiC).</p>
        </div>
      </div>
    </section>
  )
}

export default Theme
import React from "react"
import useACMIcon from "../../hooks/useACMIcon"
import Img from "gatsby-image"
import { Link } from "gatsby"

const CTA = () => {
  return (
    <section className="flex flex-col bg-w-175 md:bg-cover justify-center items-center px-8 md:px-16 py-32 relative">
      <div className="h-full w-full absolute z-0">
        <Img
          className="h-full w-full"
          fluid={useACMIcon()}
          imgStyle={{
            objectPosition: "center center",
          }}
        />
      </div>
      <h1 className="text-center md:animate-bounce text-3xl font-bold text-primary z-10">
        View Recorded Event
      </h1>
      <Link
        to="thank-you"
        className="mt-4 border border-primary focus:outline-none font-thin text-primary px-3 py-2 rounded hover:bg-primary hover:text-white transition-all ease-in text-sm z-10"
      >
        Click Here
      </Link>
    </section>
  )
}

export default CTA

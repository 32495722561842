import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import useGuidelines from "../../hooks/useGuidelines"

const Guideline = () => {
  const guidelinesImages = useGuidelines()
  return (
    <section className="shadow">
      <div className="container mx-auto flex flex-wrap justify-evenly px-6 md:px-0">
        <GuidelineItem
          text="Call for Abstracts"
          link="/guides/#abstracts"
          icon={guidelinesImages.abstract.childImageSharp.fluid}
        />
        <GuidelineItem
          text="Submission Guidelines"
          link="/guides/#submission"
          icon={guidelinesImages.guidelines.childImageSharp.fluid}
        />
        <GuidelineItem
          text="Registration"
          link="/guides/#registration"
          icon={guidelinesImages.registrations.childImageSharp.fluid}
        />
        <GuidelineItemExternal
          text="Submit Now"
          link="https://easychair.org/conferences/?conf=acmwap2021"
          icon={guidelinesImages.submit.childImageSharp.fluid}
        />
      </div>
    </section>
  )
}

const GuidelineItem = ({ text, link, icon }) => (
  <div
    className="w-1/2 lg:w-auto"
    data-sal="fade"
    data-sal-easing="ease"
    data-sal-duration="600"
  >
    <div className="bg-white py-3 md:py-6">
      <Link href={link || `/`} className="">
        <div className="flex items-center hover:text-accent text-primary transition-all ease-in transform hover:scale-105">
          <div className="bg-white mr-2 md:mr-4">
            <Img
              className="w-10 h-10 md:w-16 md:h-16 mx-auto"
              fluid={icon}
              alt="text"
              imgStyle={{
                objectFit: "cover",
                objectPosition: "center center",
              }}
            />
          </div>
          <div className="flex-1">
            <h2 className="text-sm md:text-lg font-black">{text}</h2>
          </div>
        </div>
      </Link>
    </div>
  </div>
)

const GuidelineItemExternal = ({ text, link, icon }) => (
  <div
    className="w-1/2 lg:w-auto"
    data-sal="fade"
    data-sal-easing="ease"
    data-sal-duration="600"
  >
    <div className="bg-white py-3 md:py-6">
      <a href={link || `/`} target="_blank" rel="noreferrer" className="">
        <div className="flex items-center hover:text-accent text-primary transition-all ease-in transform hover:scale-105">
          <div className="bg-white mr-2 md:mr-4">
            <Img
              className="w-10 h-10 md:w-16 md:h-16 mx-auto"
              fluid={icon}
              alt="text"
              imgStyle={{
                objectFit: "cover",
                objectPosition: "center center",
              }}
            />
          </div>
          <div className="flex-1">
            <h2 className="text-sm md:text-lg font-black">{text}</h2>
          </div>
        </div>
      </a>
    </div>
  </div>
)

export default Guideline

import { useStaticQuery, graphql } from "gatsby"

const useGuidelines = () => {
  const guidelines = useStaticQuery(graphql`
    query {
      abstract: file(relativePath: { eq: "abstract.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      guidelines: file(relativePath: { eq: "guidelines.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      registrations: file(relativePath: { eq: "registration.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      submit: file(relativePath: { eq: "submit.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return guidelines
}

export default useGuidelines

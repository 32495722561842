import React from "react"

import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Guideline from "../components/sections/guideline"
import Theme from "../components/sections/theme"
import Keynote from "../components/sections/keynote"
import CTA from "../components/sections/cta"
import Footer from "../components/sections/footer"
import ImportantDates from "../components/sections/dates"

const Index = () => (
  <Layout>
    <Navigation isSticky={false}/>
    <Header />
    <Guideline />
    <Theme />
    <ImportantDates spacing="px-6 md:px-8 lg:px-16 pt-16 pb-16 md:pb-36"/>
    <Keynote />
    <CTA />
    <Footer />
  </Layout>
)

export default Index

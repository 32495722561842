import React from "react"
import Img from "gatsby-image"
import useBanner from "../../hooks/useBanner"
import { Link } from "gatsby"

const Header = () => {
  const banner = useBanner()

  return (
    <section className="h-screen relative" id="header">
      <div className="container mx-auto flex items-center h-full p-4 pt-20 md:p-10 md:pt-24 z-10 relative">
        <div className="w-full" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">
          <h2 className="tracking-wider text-xl md:text-2xl text-accent font-semibold">APRIL 7, 2021</h2>
          <h1 className="text-3xl md:text-6xl mt-2 text-white font-black leading-snug md:leading-relaxed lg:leading-none">
            ACM Women in Computing
            <br />
            Asia Pacific
            <br />
            Celebration 2021
          </h1>
          <p className={"text-white text-lg mt-8"}>Thank you for joining this first celebration of Women in Computing in the Asia Pacific Region. See you next year!</p>
          <div className={"mt-4"}>
            <Link className={"bg-accent inline-block mr-3 px-8 py-2 rounded text-white text-md font-semibold"} to={"/thank-you"}>View Event</Link>
          </div>
        </div>
      </div>
      <div className="w-full h-full absolute z-0 top-0 left-0">
        <Img className="w-full h-full" fluid={banner} alt="Banner" imgStyle={{ objectFit: "cover", objectPosition: "center center" }}
        />
      </div>
    </section>
  )
}

export default Header
